
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_ftamc_187",
  "fit-height": "awsui_fit-height_14iqq_ftamc_223",
  "with-side-media": "awsui_with-side-media_14iqq_ftamc_228",
  "variant-default": "awsui_variant-default_14iqq_ftamc_231",
  "variant-stacked": "awsui_variant-stacked_14iqq_ftamc_231",
  "refresh": "awsui_refresh_14iqq_ftamc_239",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_ftamc_289",
  "with-top-media": "awsui_with-top-media_14iqq_ftamc_301",
  "content-wrapper": "awsui_content-wrapper_14iqq_ftamc_306",
  "content-wrapper-fit-height": "awsui_content-wrapper-fit-height_14iqq_ftamc_311",
  "media": "awsui_media_14iqq_ftamc_318",
  "media-top": "awsui_media-top_14iqq_ftamc_336",
  "media-side": "awsui_media-side_14iqq_ftamc_341",
  "header": "awsui_header_14iqq_ftamc_347",
  "header-with-media": "awsui_header-with-media_14iqq_ftamc_352",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_ftamc_358",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_ftamc_362",
  "header-stuck": "awsui_header-stuck_14iqq_ftamc_368",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_ftamc_378",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_ftamc_381",
  "with-paddings": "awsui_with-paddings_14iqq_ftamc_387",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_ftamc_396",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_ftamc_454",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_14iqq_ftamc_474",
  "dark-header": "awsui_dark-header_14iqq_ftamc_483",
  "content": "awsui_content_14iqq_ftamc_306",
  "content-fit-height": "awsui_content-fit-height_14iqq_ftamc_491",
  "content-with-media": "awsui_content-with-media_14iqq_ftamc_501",
  "footer": "awsui_footer_14iqq_ftamc_505",
  "with-divider": "awsui_with-divider_14iqq_ftamc_509"
};
  
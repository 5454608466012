
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18582_1077b_143",
  "child": "awsui_child_18582_1077b_147",
  "horizontal": "awsui_horizontal_18582_1077b_158",
  "horizontal-xxxs": "awsui_horizontal-xxxs_18582_1077b_162",
  "horizontal-xxs": "awsui_horizontal-xxs_18582_1077b_165",
  "horizontal-xs": "awsui_horizontal-xs_18582_1077b_168",
  "horizontal-s": "awsui_horizontal-s_18582_1077b_171",
  "horizontal-m": "awsui_horizontal-m_18582_1077b_174",
  "horizontal-l": "awsui_horizontal-l_18582_1077b_177",
  "horizontal-xl": "awsui_horizontal-xl_18582_1077b_180",
  "horizontal-xxl": "awsui_horizontal-xxl_18582_1077b_183",
  "vertical": "awsui_vertical_18582_1077b_190",
  "vertical-xxxs": "awsui_vertical-xxxs_18582_1077b_193",
  "vertical-xxs": "awsui_vertical-xxs_18582_1077b_196",
  "vertical-xs": "awsui_vertical-xs_18582_1077b_199",
  "vertical-s": "awsui_vertical-s_18582_1077b_202",
  "vertical-m": "awsui_vertical-m_18582_1077b_205",
  "vertical-l": "awsui_vertical-l_18582_1077b_208",
  "vertical-xl": "awsui_vertical-xl_18582_1077b_211",
  "vertical-xxl": "awsui_vertical-xxl_18582_1077b_214",
  "align-center": "awsui_align-center_18582_1077b_218",
  "align-start": "awsui_align-start_18582_1077b_222",
  "align-end": "awsui_align-end_18582_1077b_226"
};
  